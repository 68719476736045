import {CardMedia, Stack, Typography} from '@mui/material';
import {Button} from '@verily-src/react-design-system';
import {Trans, useTranslation} from 'react-i18next';
import {toCommonAssetsPath} from '../../utils';
import AccountCreationBox from '../account-creation-box';

export default function VerifyEmailLayout(props: {
  email: string;
  resendPasswordResetEmail: () => void;
  resendPasswordEmailIsLoading: boolean;
}): JSX.Element {
  const {t} = useTranslation();
  const logo = toCommonAssetsPath('verily_logo.png');
  return (
    <AccountCreationBox>
      <Stack justifyContent="center" alignItems="center" spacing={3}>
        <CardMedia
          component="img"
          src={logo}
          alt="Verily logo"
          sx={{
            maxWidth: '100px',
          }}
        />
        <Typography
          width="90%"
          variant="body1em"
          component="h2"
          textAlign="center"
        >
          {t('verify-email.please-verify-email')}
        </Typography>
        <Typography
          width="90%"
          variant="body2"
          component="h2"
          textAlign="center"
        >
          <Trans
            i18nKey="verify-email.we-sent-email"
            values={{userEmail: props.email}}
            components={{b: <strong />}}
          />
        </Typography>
        <Typography
          width="90%"
          variant="body2"
          component="h2"
          textAlign="center"
        >
          {t('verify-email.please-check-inbox')}
        </Typography>
        <Button
          variant="text"
          color="primary"
          label={t('verify-email.resend-email')}
          onClick={() => props.resendPasswordResetEmail()}
          style={{width: '100%'}}
          loading={props.resendPasswordEmailIsLoading}
        ></Button>
      </Stack>
    </AccountCreationBox>
  );
}
