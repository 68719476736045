import {ApplicationSuite} from '@verily-src/phaf-runtime-helpers';
import {config as phafConfig} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {api as phafUnifiedApi, UnifiedApi} from '@verily-src/phaf-unified-api';
import {SnackbarProvider} from '@verily-src/react-design-system';
import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import Loading from './components/loading';
import RootVerilyMeWebEnrollment from './components/RootVerilyMeWeb';
import {setFavicon, toCommonAssetsPath} from './components/utils';
import {EnrollmentThemeProvider} from './hooks/useEnrollmentTheme';
import './i18n';
import {AppRouteProvider} from './routing';

const root = document.getElementById('root');

export default function App() {
  // Sets the default favicon in the link element set by the root config.
  // A separate hook within Root overrides this by profile as necessary.
  setFavicon(toCommonAssetsPath('favicon.ico'));

  const runningInVerilyMeWeb =
    (phafUnifiedApi as UnifiedApi).getApplicationSuite() ===
    ApplicationSuite.VERILY_ME;

  return (
    <React.StrictMode>
      <EnrollmentThemeProvider>
        <Suspense fallback={<Loading />}>
          <SnackbarProvider>
            {phafConfig.getBoolean(
              'FEATURE_WORKFLOW_DRIVEN_ENROLLMENT_ENABLED'
            ) && runningInVerilyMeWeb ? (
              <RootVerilyMeWebEnrollment />
            ) : (
              <AppRouteProvider />
            )}
          </SnackbarProvider>
        </Suspense>
      </EnrollmentThemeProvider>
    </React.StrictMode>
  );
}

if (root) {
  createRoot(root).render(<App />);
}
