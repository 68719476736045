import {Stack, useTheme} from '@mui/material';
import {Checkbox, Link, OpenInNewIcon} from '@verily-src/react-design-system';
import {AgreementConsentContent} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {Controller, useFormContext} from 'react-hook-form';

export default function ConsentCheckboxLayout(
  consent: AgreementConsentContent,
  idx: number
) {
  const {control} = useFormContext();
  const theme = useTheme();
  const linkModule = consent.modules.find(module => {
    return module.module.oneofKind === 'links';
  });
  if (!linkModule) {
    return;
  }
  const link = linkModule.module['links'].links[0];
  if (!link) {
    return;
  }
  const checkboxModule = consent.modules.find(module => {
    return module.module.oneofKind === 'checkbox';
  });
  if (!checkboxModule) {
    return;
  }
  const firstSigningReason =
    checkboxModule.module['checkbox'].signingReasons[0];
  if (!firstSigningReason) {
    return;
  }
  return (
    <Stack direction="row" key={idx}>
      <Controller
        name={`checkboxReasons.${idx}`}
        key={`checkboxReasons.${idx}`}
        control={control}
        render={({field: {ref: _, ...field}}) => (
          <Checkbox
            data-cy="policy-checkbox"
            data-testid="policy-checkbox"
            {...field}
            checked={field.value || false}
            labelContainerSx={{
              backgroundColor: theme.palette.background.paper,
            }}
            sx={{
              borderRadius: 2,
            }}
            label={
              <Stack direction="row" alignItems="center" spacing={2}>
                <b>{firstSigningReason}</b>
                <Link
                  target={'_blank'} // Open in a new window.
                  href={link.url}
                  aria-label={link.text}
                >
                  <OpenInNewIcon />
                </Link>
              </Stack>
            }
          />
        )}
      />
    </Stack>
  );
}
