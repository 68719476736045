import {Grid, Typography, useTheme} from '@mui/material';
import Stack from '@mui/material/Stack';
import {Button, TextField} from '@verily-src/react-design-system';
import {
  AgreementConsentContent,
  UserType,
} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {Controller, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {WithErrorText} from '../participant-data/subforms/utils';
import ConsentCheckboxLayout from './subforms/consent-checkbox-layout';

type ParticipantDataLayoutProps = {
  submit: () => void;
  consents: AgreementConsentContent[];
  isLoading?: boolean;
  policiesAgreedTo: boolean;
  userType: UserType;
};

const HeaderLayout = () => {
  return (
    <header>
      <Typography variant="display6" component="h1" display="flex" gutterBottom>
        You’re almost done!
      </Typography>
      <Typography variant="body1" gutterBottom>
        Review and agree to the terms & conditions and provide your full legal
        name in the designated field below to continue.
      </Typography>
    </header>
  );
};

const ConsentCardsLayout = (props: {consents: AgreementConsentContent[]}) => {
  return (
    <Stack spacing={1}>
      {props.consents.map((consent, idx) => {
        return ConsentCheckboxLayout(consent, idx);
      })}
    </Stack>
  );
};

const ParticipantDataFormLayout = () => {
  const {control, formState} = useFormContext();
  const {t} = useTranslation();
  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        <Controller
          control={control}
          name={'firstName'}
          render={({field: {ref: _, ...field}, fieldState: {error}}) => (
            <WithErrorText error={error}>
              <TextField
                required
                data-cy="first-name"
                {...field}
                label={t('patientData.first-name')}
                placeholder={t('patientData.first-name')}
                inputProps={{'aria-label': t('patientData.first-name')}}
                disabled={formState.isSubmitting}
                error={!!error}
                fullWidth={true}
                multiline={false}
              />
            </WithErrorText>
          )}
        />
      </Stack>
      <Stack spacing={1}>
        <Controller
          control={control}
          name={'lastName'}
          render={({field: {ref: _, ...field}, fieldState: {error}}) => (
            <WithErrorText error={error}>
              <TextField
                required
                data-cy="last-name"
                {...field}
                label={t('patientData.last-name')}
                placeholder={t('patientData.last-name')}
                inputProps={{'aria-label': t('patientData.last-name')}}
                disabled={formState.isSubmitting}
                error={!!error}
                fullWidth={true}
                multiline={false}
              />
            </WithErrorText>
          )}
        />
      </Stack>
    </Stack>
  );
};

type FooterProps = {
  submit: () => void;
};
const ParticipantDataFooterLayout = (props: FooterProps) => {
  const {formState} = useFormContext();
  const theme = useTheme();

  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.background.canvas,
        padding: '16px',
        borderTop: '1px solid',
        borderColor: 'neutral.divider',
      }}
      spacing={1}
    >
      <Button
        data-cy="submit-button"
        data-testid="submit-button"
        label="Acknowledge & Continue"
        variant="filled"
        size="large"
        loading={formState.isSubmitting}
        disabled={!formState.isValid}
        onClick={props.submit}
      />
    </Stack>
  );
};

const ParticipantDataLayout = ({
  consents,
  submit,
}: ParticipantDataLayoutProps) => {
  return (
    <Grid
      container
      data-testid="grid"
      sx={{
        display: 'flex',
        height: '100vh',
        overflow: {md: 'hidden'},
      }}
    >
      <Grid
        item
        key="main-area"
        sx={{
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        <form style={{height: '100%'}} onSubmit={submit}>
          <Stack sx={{overflow: 'hidden', height: '100%'}}>
            <Stack
              sx={{
                overflow: 'hidden',
                overflowY: 'auto',
                height: '100%',
                display: 'inline',
                padding: '16px',
              }}
              spacing={2}
            >
              <HeaderLayout />
              <Stack spacing={3}>
                <ConsentCardsLayout consents={consents} />
                <ParticipantDataFormLayout />
              </Stack>
            </Stack>
            <ParticipantDataFooterLayout submit={submit} />
          </Stack>
        </form>
      </Grid>
    </Grid>
  );
};

export default ParticipantDataLayout;
