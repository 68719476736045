import {RpcOptions} from '@protobuf-ts/runtime-rpc';
import {
  GetUserStateRequest,
  GetUserStateResponse,
} from '@verily-src/verily1-protos/enrollment/bff/api/v2/server';
import {getRpcOptionsMaybeMockingCiam} from '../../utils/clientCaller';
import {client} from './index';

export const makeGetUserStateCall = async (
  req: GetUserStateRequest,
  opts: RpcOptions
): Promise<GetUserStateResponse> => {
  const call = await client.getUserState(req, opts);
  return call.response;
};

export const getUserState = async (): Promise<GetUserStateResponse> => {
  const req = {};
  const res = await makeGetUserStateCall(
    req,
    await getRpcOptionsMaybeMockingCiam()
  );
  return res;
};

/**
 * Similar to the above, but a Fetcher function for use with SWR
 *
 * key can just be a string representing the grpc as it's only used
 * by SWR for cache purposes
 *
 * see https://swr.vercel.app/docs/mutation#useswrmutation-api
 *
 * @returns true if the call is successful
 */
export const getUserStateSWRFetcher = async (
  _key: string
): Promise<GetUserStateResponse> => {
  return await getUserState();
};
