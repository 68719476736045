import {RpcOptions} from '@protobuf-ts/runtime-rpc';
import {
  ResendPasswordResetEmailRequest,
  ResendPasswordResetEmailResponse,
} from '@verily-src/verily1-protos/enrollment/bff/api/v2/server';
import {getRpcOptionsMaybeMockingCiam} from '../../utils/clientCaller';
import {client} from './index';

export const makeResendPasswordResetEmailCall = async (
  req: ResendPasswordResetEmailRequest,
  opts: RpcOptions
): Promise<ResendPasswordResetEmailResponse> => {
  const call = await client.resendPasswordResetEmail(req, opts);
  return call.response;
};
export const resendPasswordResetEmail = async (
  actionName: string,
  email: string,
  auth0Id: string
): Promise<void> => {
  const req: ResendPasswordResetEmailRequest = {
    actionName: actionName,
    emailAddress: email,
    auth0Id: auth0Id,
  };
  await makeResendPasswordResetEmailCall(
    req,
    await getRpcOptionsMaybeMockingCiam()
  );
};
