import {Typography, useTheme} from '@mui/material';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import {Dialog} from '@verily-src/react-design-system';
import i18n from 'i18next';
import {Dispatch, FC, SetStateAction} from 'react';

export interface ParticipantDataInfo {
  label: string;
  value: string;
}

interface ConfirmParticipantDataModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  participantInfo: ParticipantDataInfo[];
  handleContinue: () => void;
}

const ModalTableCell = styled(TableCell)({
  padding: 0,
});

export const ConfirmParticipantDataModal: FC<
  ConfirmParticipantDataModalProps
> = ({
  open,
  setOpen,
  participantInfo,
  handleContinue,
}: ConfirmParticipantDataModalProps) => {
  const theme = useTheme();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {open && (
        <Dialog
          data-testid="confirm-participant-info"
          title={i18n.t('patientDataModal.header')}
          primaryAction={{
            text: i18n.t('patientDataModal.back-button-label'),
            callback: handleClose,
          }}
          secondaryAction={{
            text: i18n.t('patientDataModal.continue-button-label'),
            callback: handleContinue,
          }}
        >
          <Typography variant="body1">
            {i18n.t('patientDataModal.subheader')}
          </Typography>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none',
              },
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(2),
            }}
          >
            <TableBody data-testid="participant-info-modal-table-body">
              {participantInfo.map((field, idx) => (
                <TableRow key={`participant-info-modal-field-${idx}`}>
                  <ModalTableCell>
                    <Typography variant="body1">
                      <strong>{field.label}</strong>
                    </Typography>
                  </ModalTableCell>
                  <ModalTableCell>
                    <Typography variant="body1">{field.value}</Typography>
                  </ModalTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Typography variant="body1">
            {i18n.t('patientDataModal.confirmation-question')}
          </Typography>
        </Dialog>
      )}
    </div>
  );
};
