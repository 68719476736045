import {RpcOptions} from '@protobuf-ts/runtime-rpc';
import {ReportErrorRequest} from '@verily-src/verily1-protos/enrollment/bff/api/v2/server';
import {getRpcOptionsMaybeMockingCiam} from '../../utils/clientCaller';
import {client} from './index';

export const makeReportErrorCall = async (
  req: ReportErrorRequest,
  opts: RpcOptions
): Promise<void> => {
  await client.reportError(req, opts);
};

export const reportError = async (error: Error): Promise<void> => {
  const req: ReportErrorRequest = {
    location: window.location.href,
    message: error.message,
    stack: error.stack || '',
  };
  await makeReportErrorCall(req, await getRpcOptionsMaybeMockingCiam());
};
