import {Auth} from '@verily-src/auth';

import {useEffect, useState} from 'react';
import {useBundle} from '../../hooks/useBundle';
import {createAccount} from '../../lib/apiv2/createAccount';
import {forceLogin} from '../../lib/apiv2/forceLogin';
import {resendPasswordResetEmail} from '../../lib/apiv2/resendPasswordResetEmail';
import {AccountCreationLayout} from '../accountCreation/create-account/layout';
import VerifyEmailLayout from '../accountCreation/verify-email/layout';
import Loading from '../loading';

export default function AccountCreationV2({actionName}: {actionName: string}) {
  const [accountEmail, setAccountEmail] = useState<string>('');
  const [loggedIn, setLoggedIn] = useState<boolean | null>(null);
  const [signInLoading, setSignInLoading] = useState<boolean>(false);
  const [auth0Id, setAuth0Id] = useState<string | null>(null);
  const [createAccountInProgress, setCreateAccountInProgress] =
    useState<boolean>(false);
  const [resendPasswordEmailIsLoading, setResendPasswordEmailIsLoading] =
    useState<boolean>(false);
  const {advance} = useBundle();
  // We expect that the user's login state will not change within a single
  // mounting of this component, so we don't need to re-run it based on any
  // dependencies within that scope.
  useEffect(() => {
    Auth.isLoggedIn().then(loggedIn => {
      setLoggedIn(loggedIn);
    });
  }, []);
  if (loggedIn) {
    advance();
  }
  // If we don't yet know whether the user is logged in, don't render anything
  // that would risk giving them the opportunity to create a duplicate account.
  if (loggedIn === null) {
    return <Loading />;
  }

  const resend = () => {
    setResendPasswordEmailIsLoading(true);
    resendPasswordResetEmail(actionName, accountEmail, auth0Id).then(() => {
      setResendPasswordEmailIsLoading(false);
    });
  };
  // Once we've gotten to this point, we know that loggedIn is false.
  if (accountEmail && !createAccountInProgress) {
    return (
      <VerifyEmailLayout
        email={accountEmail}
        resendPasswordResetEmail={resend}
        resendPasswordEmailIsLoading={resendPasswordEmailIsLoading}
      />
    );
  }
  // Render the "Create Account" page.
  return (
    <AccountCreationLayout
      onSubmit={function (
        email: string,
        _setAccountAlreadyExistsMessage: Function
      ) {
        // TODO(PHP-32180): add real error handling.
        setAccountEmail(email);
        setCreateAccountInProgress(true);
        createAccount(actionName, email).then((auth0Id: string) => {
          setAuth0Id(auth0Id);
          setCreateAccountInProgress(false);
        });
      }}
      submitLoading={createAccountInProgress}
      onSignIn={() => {
        setSignInLoading(true);
        void forceLogin();
      }}
      signInLoading={signInLoading}
    />
  );
}
