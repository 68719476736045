import {RpcOptions} from '@protobuf-ts/runtime-rpc';
import {
  CompleteEnrollmentRequest,
  CompleteEnrollmentResponse,
} from '@verily-src/verily1-protos/enrollment/bff/api/v2/server';
import {getRpcOptionsMaybeMockingCiam} from '../../utils/clientCaller';
import {client} from './index';

export const makeCompleteEnrollmentCall = async (
  req: CompleteEnrollmentRequest,
  opts: RpcOptions
): Promise<CompleteEnrollmentResponse> => {
  const call = await client.completeEnrollment(req, opts);
  return call.response;
};

export type completeEnrollmentFetcherKey = {
  grpcName: string;
  actionName: string;
};

/**
 * Fetcher function for use with SWR
 *
 * see https://swr.vercel.app/docs/mutation#useswrmutation-api
 *
 * @returns true if the call is successful
 */
export const completeEnrollmentSWRFetcher = async (
  fetcherKey: completeEnrollmentFetcherKey,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  {arg}: {arg: string}
): Promise<boolean> => {
  const req: CompleteEnrollmentRequest = {
    actionName: fetcherKey.actionName,
  };
  await makeCompleteEnrollmentCall(req, await getRpcOptionsMaybeMockingCiam());
  return true;
};
