import {api as phafUnifiedApi} from '@verily-src/phaf-unified-api';
import {type api as VerilyMeApi} from '@verily-src/verily-me-api';

/**
 * Hook for calling the bundle utility.
 *
 * This exists primarily to simplify component unit tests
 * by abstracting out the phaf-unified-api and verily me api dependencies.
 */
export function useBundle() {
  const bundle = (phafUnifiedApi as typeof VerilyMeApi).bundle;
  return {
    advance: bundle.advance,
  };
}
