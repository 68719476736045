import {createContext, useContext} from 'react';
import {api} from '../lib/api';
import {AppConfig} from '../lib/types/appConfig';

import {useSWRImmutable} from '../swr';
import {useProfile} from './useProfile';

export type UseConfigReturn = {
  config: AppConfig;
};

export const ConfigContext = createContext<AppConfig>({
  productName: '',
  appHandoffLink: '',
  webHandoffLink: '',
  disclaimerFooter: '',
  collectGenderIdentity: false,
  collectAddress: false,
  collectDob: false,
  collectEmail: false,
  collectPhone: false,
  formFieldConstraints: new Map(),
  eligibility: {
    checkEligibility: false,
  },
  inclusion: {
    questionSections: [],
  },
  exclusion: {
    questionSections: [],
  },
  hasAgreementConsents: false,
});

export function useFetchConfig() {
  const {profileName} = useProfile();

  const {data} = useSWRImmutable({profileName}, api.getProfileConfig);

  return {config: data};
}

export const useConfig = (): UseConfigReturn => ({
  config: useContext(ConfigContext),
});
