/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "enrollment/bff/api/v2/server.proto" (package "enrollment.bffv2", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Enrollment } from "./server";
import type { Empty } from "../../../../google/protobuf/empty";
import type { GetPasswordResetReturnProfileResponse } from "./server";
import type { GetPasswordResetReturnProfileRequest } from "./server";
import type { CheckAuthenticationResponse } from "./server";
import type { CheckAuthenticationRequest } from "./server";
import type { ReportErrorResponse } from "./server";
import type { ReportErrorRequest } from "./server";
import type { GetUserStateResponse } from "./server";
import type { GetUserStateRequest } from "./server";
import type { StartEnrollmentResponse } from "./server";
import type { StartEnrollmentRequest } from "./server";
import type { RecordUTMResponse } from "./server";
import type { RecordUTMRequest } from "./server";
import type { RecordEnrolleeEventResponse } from "./server";
import type { RecordEnrolleeEventRequest } from "./server";
import type { GetConfigResponse } from "./server";
import type { GetConfigRequest } from "./server";
import type { CompleteEnrollmentResponse } from "./server";
import type { CompleteEnrollmentRequest } from "./server";
import type { CheckEligibilityResponse } from "./server";
import type { CheckEligibilityRequest } from "./server";
import type { GetAgreementConsentsResponse } from "./server";
import type { GetAgreementConsentsRequest } from "./server";
import type { ResendPasswordResetEmailResponse } from "./server";
import type { ResendPasswordResetEmailRequest } from "./server";
import type { CreateAccountResponse } from "./server";
import type { CreateAccountRequest } from "./server";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { StartSessionResponse } from "./server";
import type { StartSessionRequest } from "./server";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service enrollment.bffv2.Enrollment
 */
export interface IEnrollmentClient {
    /**
     * @generated from protobuf rpc: StartSession(enrollment.bffv2.StartSessionRequest) returns (enrollment.bffv2.StartSessionResponse);
     */
    startSession(input: StartSessionRequest, options?: RpcOptions): UnaryCall<StartSessionRequest, StartSessionResponse>;
    /**
     * This RPC assumes that a session exists and that its information is attached
     * as metadata to the CreateAccount call.
     *
     * @generated from protobuf rpc: CreateAccount(enrollment.bffv2.CreateAccountRequest) returns (enrollment.bffv2.CreateAccountResponse);
     */
    createAccount(input: CreateAccountRequest, options?: RpcOptions): UnaryCall<CreateAccountRequest, CreateAccountResponse>;
    /**
     * @generated from protobuf rpc: ResendPasswordResetEmail(enrollment.bffv2.ResendPasswordResetEmailRequest) returns (enrollment.bffv2.ResendPasswordResetEmailResponse);
     */
    resendPasswordResetEmail(input: ResendPasswordResetEmailRequest, options?: RpcOptions): UnaryCall<ResendPasswordResetEmailRequest, ResendPasswordResetEmailResponse>;
    /**
     * Returns information about the agreement consents which were instantiated
     * for the user based on the agreement_consents_config field.
     *
     * @generated from protobuf rpc: GetAgreementConsents(enrollment.bffv2.GetAgreementConsentsRequest) returns (enrollment.bffv2.GetAgreementConsentsResponse);
     */
    getAgreementConsents(input: GetAgreementConsentsRequest, options?: RpcOptions): UnaryCall<GetAgreementConsentsRequest, GetAgreementConsentsResponse>;
    /**
     * @generated from protobuf rpc: CheckEligibility(enrollment.bffv2.CheckEligibilityRequest) returns (enrollment.bffv2.CheckEligibilityResponse);
     */
    checkEligibility(input: CheckEligibilityRequest, options?: RpcOptions): UnaryCall<CheckEligibilityRequest, CheckEligibilityResponse>;
    /**
     * @generated from protobuf rpc: CompleteEnrollment(enrollment.bffv2.CompleteEnrollmentRequest) returns (enrollment.bffv2.CompleteEnrollmentResponse);
     */
    completeEnrollment(input: CompleteEnrollmentRequest, options?: RpcOptions): UnaryCall<CompleteEnrollmentRequest, CompleteEnrollmentResponse>;
    /**
     * @generated from protobuf rpc: GetConfig(enrollment.bffv2.GetConfigRequest) returns (enrollment.bffv2.GetConfigResponse);
     */
    getConfig(input: GetConfigRequest, options?: RpcOptions): UnaryCall<GetConfigRequest, GetConfigResponse>;
    /**
     * @generated from protobuf rpc: RecordEnrolleeEvent(enrollment.bffv2.RecordEnrolleeEventRequest) returns (enrollment.bffv2.RecordEnrolleeEventResponse);
     */
    recordEnrolleeEvent(input: RecordEnrolleeEventRequest, options?: RpcOptions): UnaryCall<RecordEnrolleeEventRequest, RecordEnrolleeEventResponse>;
    /**
     * @generated from protobuf rpc: RecordUTM(enrollment.bffv2.RecordUTMRequest) returns (enrollment.bffv2.RecordUTMResponse);
     */
    recordUTM(input: RecordUTMRequest, options?: RpcOptions): UnaryCall<RecordUTMRequest, RecordUTMResponse>;
    /**
     * @generated from protobuf rpc: StartEnrollment(enrollment.bffv2.StartEnrollmentRequest) returns (enrollment.bffv2.StartEnrollmentResponse);
     */
    startEnrollment(input: StartEnrollmentRequest, options?: RpcOptions): UnaryCall<StartEnrollmentRequest, StartEnrollmentResponse>;
    /**
     * @generated from protobuf rpc: GetUserState(enrollment.bffv2.GetUserStateRequest) returns (enrollment.bffv2.GetUserStateResponse);
     */
    getUserState(input: GetUserStateRequest, options?: RpcOptions): UnaryCall<GetUserStateRequest, GetUserStateResponse>;
    /**
     * @generated from protobuf rpc: ReportError(enrollment.bffv2.ReportErrorRequest) returns (enrollment.bffv2.ReportErrorResponse);
     */
    reportError(input: ReportErrorRequest, options?: RpcOptions): UnaryCall<ReportErrorRequest, ReportErrorResponse>;
    /**
     * @generated from protobuf rpc: CheckAuthentication(enrollment.bffv2.CheckAuthenticationRequest) returns (enrollment.bffv2.CheckAuthenticationResponse);
     */
    checkAuthentication(input: CheckAuthenticationRequest, options?: RpcOptions): UnaryCall<CheckAuthenticationRequest, CheckAuthenticationResponse>;
    /**
     * @generated from protobuf rpc: GetPasswordResetReturnProfile(enrollment.bffv2.GetPasswordResetReturnProfileRequest) returns (enrollment.bffv2.GetPasswordResetReturnProfileResponse);
     */
    getPasswordResetReturnProfile(input: GetPasswordResetReturnProfileRequest, options?: RpcOptions): UnaryCall<GetPasswordResetReturnProfileRequest, GetPasswordResetReturnProfileResponse>;
    /**
     * TODO(PHP-37988) get rid of this in favor of a purely frontend solution.
     *
     * @generated from protobuf rpc: ForceLogin(google.protobuf.Empty) returns (google.protobuf.Empty);
     */
    forceLogin(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty>;
}
/**
 * @generated from protobuf service enrollment.bffv2.Enrollment
 */
export class EnrollmentClient implements IEnrollmentClient, ServiceInfo {
    typeName = Enrollment.typeName;
    methods = Enrollment.methods;
    options = Enrollment.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: StartSession(enrollment.bffv2.StartSessionRequest) returns (enrollment.bffv2.StartSessionResponse);
     */
    startSession(input: StartSessionRequest, options?: RpcOptions): UnaryCall<StartSessionRequest, StartSessionResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<StartSessionRequest, StartSessionResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * This RPC assumes that a session exists and that its information is attached
     * as metadata to the CreateAccount call.
     *
     * @generated from protobuf rpc: CreateAccount(enrollment.bffv2.CreateAccountRequest) returns (enrollment.bffv2.CreateAccountResponse);
     */
    createAccount(input: CreateAccountRequest, options?: RpcOptions): UnaryCall<CreateAccountRequest, CreateAccountResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateAccountRequest, CreateAccountResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ResendPasswordResetEmail(enrollment.bffv2.ResendPasswordResetEmailRequest) returns (enrollment.bffv2.ResendPasswordResetEmailResponse);
     */
    resendPasswordResetEmail(input: ResendPasswordResetEmailRequest, options?: RpcOptions): UnaryCall<ResendPasswordResetEmailRequest, ResendPasswordResetEmailResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<ResendPasswordResetEmailRequest, ResendPasswordResetEmailResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Returns information about the agreement consents which were instantiated
     * for the user based on the agreement_consents_config field.
     *
     * @generated from protobuf rpc: GetAgreementConsents(enrollment.bffv2.GetAgreementConsentsRequest) returns (enrollment.bffv2.GetAgreementConsentsResponse);
     */
    getAgreementConsents(input: GetAgreementConsentsRequest, options?: RpcOptions): UnaryCall<GetAgreementConsentsRequest, GetAgreementConsentsResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAgreementConsentsRequest, GetAgreementConsentsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CheckEligibility(enrollment.bffv2.CheckEligibilityRequest) returns (enrollment.bffv2.CheckEligibilityResponse);
     */
    checkEligibility(input: CheckEligibilityRequest, options?: RpcOptions): UnaryCall<CheckEligibilityRequest, CheckEligibilityResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<CheckEligibilityRequest, CheckEligibilityResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CompleteEnrollment(enrollment.bffv2.CompleteEnrollmentRequest) returns (enrollment.bffv2.CompleteEnrollmentResponse);
     */
    completeEnrollment(input: CompleteEnrollmentRequest, options?: RpcOptions): UnaryCall<CompleteEnrollmentRequest, CompleteEnrollmentResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<CompleteEnrollmentRequest, CompleteEnrollmentResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetConfig(enrollment.bffv2.GetConfigRequest) returns (enrollment.bffv2.GetConfigResponse);
     */
    getConfig(input: GetConfigRequest, options?: RpcOptions): UnaryCall<GetConfigRequest, GetConfigResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetConfigRequest, GetConfigResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RecordEnrolleeEvent(enrollment.bffv2.RecordEnrolleeEventRequest) returns (enrollment.bffv2.RecordEnrolleeEventResponse);
     */
    recordEnrolleeEvent(input: RecordEnrolleeEventRequest, options?: RpcOptions): UnaryCall<RecordEnrolleeEventRequest, RecordEnrolleeEventResponse> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<RecordEnrolleeEventRequest, RecordEnrolleeEventResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RecordUTM(enrollment.bffv2.RecordUTMRequest) returns (enrollment.bffv2.RecordUTMResponse);
     */
    recordUTM(input: RecordUTMRequest, options?: RpcOptions): UnaryCall<RecordUTMRequest, RecordUTMResponse> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<RecordUTMRequest, RecordUTMResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: StartEnrollment(enrollment.bffv2.StartEnrollmentRequest) returns (enrollment.bffv2.StartEnrollmentResponse);
     */
    startEnrollment(input: StartEnrollmentRequest, options?: RpcOptions): UnaryCall<StartEnrollmentRequest, StartEnrollmentResponse> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<StartEnrollmentRequest, StartEnrollmentResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUserState(enrollment.bffv2.GetUserStateRequest) returns (enrollment.bffv2.GetUserStateResponse);
     */
    getUserState(input: GetUserStateRequest, options?: RpcOptions): UnaryCall<GetUserStateRequest, GetUserStateResponse> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserStateRequest, GetUserStateResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ReportError(enrollment.bffv2.ReportErrorRequest) returns (enrollment.bffv2.ReportErrorResponse);
     */
    reportError(input: ReportErrorRequest, options?: RpcOptions): UnaryCall<ReportErrorRequest, ReportErrorResponse> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReportErrorRequest, ReportErrorResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CheckAuthentication(enrollment.bffv2.CheckAuthenticationRequest) returns (enrollment.bffv2.CheckAuthenticationResponse);
     */
    checkAuthentication(input: CheckAuthenticationRequest, options?: RpcOptions): UnaryCall<CheckAuthenticationRequest, CheckAuthenticationResponse> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<CheckAuthenticationRequest, CheckAuthenticationResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPasswordResetReturnProfile(enrollment.bffv2.GetPasswordResetReturnProfileRequest) returns (enrollment.bffv2.GetPasswordResetReturnProfileResponse);
     */
    getPasswordResetReturnProfile(input: GetPasswordResetReturnProfileRequest, options?: RpcOptions): UnaryCall<GetPasswordResetReturnProfileRequest, GetPasswordResetReturnProfileResponse> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPasswordResetReturnProfileRequest, GetPasswordResetReturnProfileResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * TODO(PHP-37988) get rid of this in favor of a purely frontend solution.
     *
     * @generated from protobuf rpc: ForceLogin(google.protobuf.Empty) returns (google.protobuf.Empty);
     */
    forceLogin(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, Empty>("unary", this._transport, method, opt, input);
    }
}
