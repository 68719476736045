export const ROUTE_STUBS = {
  profile: 'profile',
  profileDynamicSegment: '/:profileName',
  enroll: 'enroll',
  enrollmentMfePath: 'enrollment',
  participantData: 'participant-data',
  participantDataNux: 'participant-data-nux',
  ineligible: 'ineligible',
  inclusion: 'inclusion',
  login: 'login',
  authCallback: 'authCallback', // auth0 callback
  exclusion: 'exclusion',
  handoff: 'handoff',
  // Alternate completion state to product handoff page
  completionRedirect: 'completion-redirect',
  screenedOut: 'screened-out',
  eligUserAlreadyEnrolled: 'elig-user-already-enrolled',
  logout: 'logout',
  accountCreation: 'create-account',
  passwordResetRedirect: 'redirect',
  profileRedirect: 'profile-redirect',
  verifyEmail: 'verify-email',
  error: 'error',
};

export const LOGOUT_PARAMS = {
  isIdp: 'isIdp',
};

export const participantDataNuxPath = (profilePath: string): string => {
  return `${profilePath}/${ROUTE_STUBS.enroll}/${ROUTE_STUBS.participantDataNux}`;
};

export const errorPath = () => {
  return `/${ROUTE_STUBS.error}`;
};

export const profilePath = (profile: string): string => {
  return `/${ROUTE_STUBS.profile}/${profile}`;
};

export const createAccountPath = (profilePath: string): string => {
  return `${profilePath}/${ROUTE_STUBS.enroll}/${ROUTE_STUBS.accountCreation}`;
};

export const verifyEmailPath = (profilePath: string): string => {
  return `${profilePath}/${ROUTE_STUBS.verifyEmail}`;
};

export const enrollFlowPath = (profilePath: string): string => {
  return `${profilePath}/${ROUTE_STUBS.enroll}`;
};

export const authCallbackPath = (profilePath: string): string => {
  return `${profilePath}/${ROUTE_STUBS.authCallback}`;
};

export const loginUrl = (profile: string): string => {
  return new URL(
    `${enrollFlowUrl(profile)}/${ROUTE_STUBS.login}`,
    window.location.origin
  ).href;
};

export const logoutUrl = (isIdp: boolean): string => {
  const logoutUrl = new URL(
    `${ROUTE_STUBS.enrollmentMfePath}/${ROUTE_STUBS.logout}`,
    window.location.origin
  );
  // note that auth0 might not respect passing multiple query paramters
  // see: https://community.auth0.com/t/logout-redirect-url-ignores-additional-url-query-parameters-in-custom-rule/73219
  if (isIdp) logoutUrl.searchParams.append(LOGOUT_PARAMS.isIdp, 'true');
  return logoutUrl.href;
};
export const enrollFlowUrl = (profileName: string): string => {
  return new URL(
    `${ROUTE_STUBS.enrollmentMfePath}${enrollFlowPath(
      profilePath(profileName)
    )}`,
    window.location.origin
  ).href;
};

export const passwordResetRedirectPath = (): string => {
  return `/${ROUTE_STUBS.passwordResetRedirect}`;
};

export const passwordResetRedirectProfilePath = (): string => {
  return `/${ROUTE_STUBS.profileRedirect}`;
};

export const passwordResetRedirectProfileUrl = (): string => {
  return new URL(
    `${ROUTE_STUBS.enrollmentMfePath}${passwordResetRedirectProfilePath()}`,
    window.location.origin
  ).href;
};
