import {EnrolleeEvent} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {EnrolleeEvent as EnrolleeEventV2} from '@verily-src/verily1-protos/enrollment/bff/api/v2/server';
import {useEffect} from 'react';
import {api as apiV2} from '../../../lib/apiv2';

import {useConfig} from '../../../hooks/useConfig';
import {useConfigsV2} from '../../../hooks/useConfigsV2';
import {useRecordEvent} from '../../../hooks/useRecordEvent';
import HandoffLayout from './layout';

export default function HandoffPage() {
  const {config} = useConfig();
  const {recordEvent} = useRecordEvent();

  useEffect(() => {
    recordEvent(EnrolleeEvent.VISIT_PRODUCT_HANDOFF);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HandoffLayout
      logo1={config.logo1}
      logo2={config.logo2}
      disclaimerFooter={config.disclaimerFooter}
      productName={config.productName}
      webHandoffLink={config.webHandoffLink}
      appHandoffLink={config.appHandoffLink}
      androidHandoffLink={config.androidHandoffLink}
      iosHandoffLink={config.iosHandoffLink}
    />
  );
}

// TODO: Make this the default export once non-Verily Me Web enrollment is deprecated
export function HandoffPageVerilyMeWeb() {
  const {configs} = useConfigsV2();

  useEffect(() => {
    apiV2.recordEnrolleeEvent(EnrolleeEventV2.VISIT_PRODUCT_HANDOFF);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HandoffLayout
      logo1={configs.common.logo1}
      logo2={configs.common.logo2}
      disclaimerFooter={configs.common.disclaimerFooter}
      productName={configs.common.productName}
      webHandoffLink={configs.completionHandoff?.webHandoffLink}
      appHandoffLink={configs.completionHandoff?.appHandoffLink}
      androidHandoffLink={configs.completionHandoff?.androidHandoffLink}
      iosHandoffLink={configs.completionHandoff?.iosHandoffLink}
    />
  );
}
