import {api as phafUnifiedApi} from '@verily-src/phaf-unified-api';
import {type api as VerilyMeApi} from '@verily-src/verily-me-api';
import {useEffect, useState} from 'react';
import useSWRImmutable from 'swr/immutable';
import {ConfigsV2Context} from '../hooks/useConfigsV2';
import {api} from '../lib/apiv2';
import AccountCreation from './accountCreationV2';
import {CompleteEnrollmentVerilyMeWeb} from './complete-enrollment/index-me-web';
import Loading from './loading';

export default function RootVerilyMeWebEnrollment() {
  // TODO(PHP-34807): Determine if favicon, page title, etc need to be set from config and implement
  // TODO(PHP-34810): Determine if session initialization is needed and implement
  const [actionName, setActionName] = useState<string>('');

  const {data: getCurrentStepData, error: getCurrentStepError} =
    useSWRImmutable(
      'bundle/getCurrentStep',
      (phafUnifiedApi as typeof VerilyMeApi).bundle.getCurrentStep
    );
  const {data: getConfigData, error: getConfigError} = useSWRImmutable(
    actionName ? [actionName] : null, // Using a null key means the fetcher will not run.
    () => api.getConfig(actionName)
  );

  useEffect(() => {
    if (getCurrentStepData) {
      setActionName(getCurrentStepData.actionName);
    }
  }, [getCurrentStepData]);

  const err = getCurrentStepError || getConfigError;
  if (err) {
    // Errors will be caught by the singleSpaReact error boundary
    throw err;
  }
  if (getConfigData) {
    let child = <></>;
    if (getConfigData.accountCreation) {
      child = <AccountCreation actionName={actionName} />;
    } else if (getConfigData.participantData) {
      // TODO(PHP-32179): Render appropriate page
      child = <div>Render participant data</div>;
    } else if (getConfigData.completionHandoff) {
      child = <CompleteEnrollmentVerilyMeWeb actionName={actionName} />;
    } else {
      // TODO: Render appropriate enrollment page based on config
      child = <div>Render appropriate enrollment page</div>;
    }
    return (
      <ConfigsV2Context.Provider value={getConfigData}>
        {child}
      </ConfigsV2Context.Provider>
    );
  }
  return <Loading />;
}
