// Need to hoist public path setting to top so ES6 imports do not come before it.
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import {ErrorElementWithoutRoute} from './components/global-error/global-error';
import {EnrollmentThemeProvider} from './hooks/useEnrollmentTheme';
import App from './index';
import './publicPath';
import reportWebVitals from './reportWebVitals';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary() {
    // Only errors thrown by the VerilyMeWebRoot component and its children will be caught
    // by this error boundary. Non Verily Me Web enrollment uses routes and handles
    // errors withing the router. The theme must be provided to the error boundary component
    // to style it appropriately.
    return (
      <EnrollmentThemeProvider>
        <ErrorElementWithoutRoute />
      </EnrollmentThemeProvider>
    );
  },
});

export const {bootstrap, mount, unmount} = lifecycles;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
