import {RpcOptions} from '@protobuf-ts/runtime-rpc';
import {
  CreateAccountRequest,
  CreateAccountResponse,
} from '@verily-src/verily1-protos/enrollment/bff/api/v2/server';
import {getRpcOptionsMaybeMockingCiam} from '../../utils/clientCaller';
import {client} from './index';

export const makeCreateAccountCall = async (
  req: CreateAccountRequest,
  opts: RpcOptions
): Promise<CreateAccountResponse> => {
  const call = await client.createAccount(req, opts);
  return call.response;
};
export const createAccount = async (
  actionName: string,
  email: string
): Promise<string> => {
  const req: CreateAccountRequest = {
    actionName: actionName,
    emailAddress: email,
    sendPasswordResetEmail: true,
  };
  const res = await makeCreateAccountCall(
    req,
    await getRpcOptionsMaybeMockingCiam()
  );
  return res.auth0Id;
};
