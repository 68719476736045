import {GrpcStatusCode} from '@protobuf-ts/grpcweb-transport';
import {RpcError} from '@protobuf-ts/runtime-rpc';
import {
  EnrollPatientResponse,
  Step,
} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {callEnroll} from '../../utils/clientCaller';
import {stateToProto} from '../proto/userState';
import {UserState} from '../types/userState';
import {EnrollmentError, EnrollmentErrorType} from './error';
import {getRpcOptions} from './getRpcOptions';

export const enrollServiceExceptionMsg =
  'Service Exception: enroll request failed';

type EnrollProps = {
  userState: UserState;
  profileName: string;
  currentStep: Step;
};

export const enroll = async ({
  userState,
  profileName,
  currentStep,
}: EnrollProps): Promise<EnrollPatientResponse> => {
  try {
    const options = await getRpcOptions();
    return await callEnroll(
      stateToProto(userState, profileName, currentStep),
      options
    );
  } catch (error) {
    if (error instanceof RpcError) {
      if (error.code === GrpcStatusCode[GrpcStatusCode.ALREADY_EXISTS]) {
        throw new EnrollmentError(
          EnrollmentErrorType.ELIGIBILITY_USER_ALREADY_ENROLLED,
          error.message,
          error
        );
      }
    }
    throw new Error(enrollServiceExceptionMsg, {cause: error});
  }
};
