import {useEffect, useState} from 'react';
import useSWRMutation from 'swr/mutation';
import {useConfigsV2} from '../../hooks/useConfigsV2';
import {api as apiV2} from '../../lib/apiv2';
import {ErrorElementWithoutRoute} from '../global-error/global-error';
import Loading from '../loading';
import {HandoffPageVerilyMeWeb} from './handoff';
import {RedirectUserVerilyMeWeb} from './redirect-user';

export type CompleteEnrollmentVerilyMeWebProps = {
  actionName: string;
};

/**
 * Completes enrollment within Verily Me Web workflow driven enrollment.
 *
 * TODO: This should eventually replace the default CompleteEnrollment component.
 * once non-Verily Me Web enrollment is removed.
 */
export function CompleteEnrollmentVerilyMeWeb(
  props: CompleteEnrollmentVerilyMeWebProps
) {
  const {configs} = useConfigsV2();
  const [hasRunCompleteEnrollment, setHasRunCompleteEnrollment] =
    useState(false);
  const {
    data: userState,
    trigger,
    error: getUserStateError,
    isMutating: isFetchingUserState,
  } = useSWRMutation('apiv2/getUserState', apiV2.getUserStateSWRFetcher, {
    // Don't throw error to parent component since we handle the error here.
    throwOnError: false,
  });
  const {
    data: setCompletedStatusSuccessfully,
    trigger: triggerCompleteEnrollment,
    error: completeEnrollmentError,
    isMutating: isRunningCompleteEnrollment,
  } = useSWRMutation(
    {grpcName: 'v2/completeEnrollment', actionName: props.actionName},
    apiV2.completeEnrollmentSWRFetcher,
    // Don't throw error to parent component since we handle the error here.
    {throwOnError: false}
  );

  useEffect(() => {
    // Make sure to get the latest updated user state
    trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getUserStateError || completeEnrollmentError) {
      return;
    }
    // Call complete enrollment once only if we've fetched the latest user state
    // and found the user has not completed enrollment.
    if (userState && !hasRunCompleteEnrollment && !userState?.isComplete) {
      triggerCompleteEnrollment();
      setHasRunCompleteEnrollment(true);
    }
  }, [
    userState,
    hasRunCompleteEnrollment,
    triggerCompleteEnrollment,
    getUserStateError,
    completeEnrollmentError,
  ]);

  const anyApiError = getUserStateError || completeEnrollmentError;
  if (anyApiError) {
    // This shows the appropriate error page based on the error type (e.g.,
    // eligibility user already enrolled error or default internal error).
    return <ErrorElementWithoutRoute error={anyApiError} />;
  }
  if (isFetchingUserState || isRunningCompleteEnrollment) {
    return <Loading />;
  }
  if (userState?.isComplete || setCompletedStatusSuccessfully) {
    return configs.completionHandoff ? (
      <HandoffPageVerilyMeWeb />
    ) : (
      <RedirectUserVerilyMeWeb />
    );
  }
  return <Loading />;
}
