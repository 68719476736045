import {Box} from '@mui/material';
import {ConsumerLightTheme} from '@verily-src/react-design-system';

type AccountCreationBoxProps = {
  children: React.ReactNode;
};

export default function AccountCreationBox({
  children,
}: AccountCreationBoxProps) {
  return (
    <Box
      style={{
        backgroundColor: ConsumerLightTheme.palette.background.canvas,
        width: '100vw',
        height: '100vh',
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        display="flex"
        alignItems="center"
        style={{
          backgroundColor: ConsumerLightTheme.palette.background.paper,
          borderRadius: '8px',
          overflowWrap: 'anywhere',
          width: '400px',
          padding: '40px 20px',
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
