import {config as phafConfig} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {
  AccountCreation,
  Handoff,
  ParticipantData,
  RedirectUserStep as RedirectUserStepProto,
  Step,
} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {EnrollmentStep} from '../../types/flow';
import {AppConfig} from './appConfig';

// Declaring these types is required since protobuf and TypeScript don't play together
// nicely and TypeScript complains that any step can be of type { oneofKind: undefined; }
// (even if we've determined that the oneofKind is defined) so it won't let us access
// the properties of the step. This is a workaround to make TypeScript happy.

export const ONEOF_KIND = {
  accountCreation: 'accountCreation' as const,
  participantData: 'participantData' as const,
  handoff: 'handoff' as const,
  redirectUser: 'redirectUser' as const,
};

export type AccountCreationStep = {
  oneofKind: typeof ONEOF_KIND.accountCreation;
  accountCreation: AccountCreation;
};

export type ParticipantDataStep = {
  oneofKind: typeof ONEOF_KIND.participantData;
  participantData: ParticipantData;
};

export type HandoffStep = {
  oneofKind: typeof ONEOF_KIND.handoff;
  handoff: Handoff;
};

export type RedirectUserStep = {
  oneofKind: typeof ONEOF_KIND.redirectUser;
  redirectUser: RedirectUserStepProto;
};

export const defaultAccountCreationStep = {
  step: {
    oneofKind: ONEOF_KIND.accountCreation,
    accountCreation: {},
  },
} as Step;

export const defaultParticipantDataStep = {
  step: {
    oneofKind: ONEOF_KIND.participantData,
    participantData: {},
  },
} as Step;

export const defaultHandoffStep = {
  step: {
    oneofKind: ONEOF_KIND.handoff,
    handoff: {},
  },
} as Step;

export const doParticipantDataNUX = (config: AppConfig): boolean => {
  return (
    phafConfig.getBoolean('FEATURE_NEW_USER_EXPERIENCE_ENABLED') &&
    !config.collectPhone &&
    !config.collectEmail &&
    !config.collectDob &&
    !config.collectAddress &&
    !config.collectGenderIdentity
  );
};

export const flowConfigStepToEnrollmentStep = (
  step: Step,
  config: AppConfig
): EnrollmentStep => {
  switch (step.step.oneofKind) {
    case ONEOF_KIND.accountCreation:
      return EnrollmentStep.ACCOUNT_CREATION;
    case ONEOF_KIND.participantData:
      if (doParticipantDataNUX(config)) {
        return EnrollmentStep.PARTICIPANT_DATA_NUX;
      } else {
        return EnrollmentStep.PARTICIPANT_DATA;
      }
    case ONEOF_KIND.handoff:
      return EnrollmentStep.HANDOFF;
    case ONEOF_KIND.redirectUser:
      return EnrollmentStep.REDIRECT_USER;
    default:
      throw new Error(`Unknown step: ${step}`);
  }
};
