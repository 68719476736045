import {ApplicationSuite} from '@verily-src/phaf-runtime-helpers';
import {config as phafConfig} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {api as phafUnifiedApi, UnifiedApi} from '@verily-src/phaf-unified-api';
import {useEffect, useState} from 'react';
import {useRouteError} from 'react-router-dom';
import {api} from '../../lib/api';
import {EnrollmentError, EnrollmentErrorType} from '../../lib/api/error';
import {api as apiV2} from '../../lib/apiv2';
import Loading from '../loading';
import {ErrorElementLayout} from './layout';

type ErrorElementProps = {
  error?: EnrollmentError | unknown;
};

function ErrorElement(props: ErrorElementProps) {
  // Because this component relies on useRouteError(), it will not work as
  // intended if rendered by something other than the router. See
  // https://verily.atlassian.net/browse/PHP-2358 for more information.
  const routeError = useRouteError();
  // if error element rendered from route, use route error -- otherwise attempt to use props error
  const error = routeError ?? props.error;

  return <ErrorElementWithoutRoute error={error} />;
}

// This logic is separated as a subcomponent since Workflow driven enrollment
// within Verily Me Web does not use routes. We can rename + use this
// as the primary error element once single-spa Enrollment is deprecated.
export function ErrorElementWithoutRoute(props: ErrorElementProps) {
  const [enrollmentErrorType, setEnrollmentErrorType] =
    useState<EnrollmentErrorType>(EnrollmentErrorType.UNKNOWN);
  const runningInVerilyMeWeb =
    (phafUnifiedApi as UnifiedApi).getApplicationSuite() ===
    ApplicationSuite.VERILY_ME;
  const error = props.error;

  useEffect(() => {
    // we don't need to report unauthenticated errors
    if (
      error instanceof EnrollmentError &&
      error.errorType === EnrollmentErrorType.UNAUTHENTICATED
    ) {
      return;
    }
    if (error instanceof Error) {
      if (
        phafConfig.getBoolean('FEATURE_WORKFLOW_DRIVEN_ENROLLMENT_ENABLED') &&
        runningInVerilyMeWeb
      ) {
        apiV2.reportError(error);
      } else {
        api.reportError(error);
      }
      if (error instanceof EnrollmentError) {
        setEnrollmentErrorType(error.errorType);
      }
    }
  }, [error]);

  // simply return a loading page for unauthenticated errors since
  // the Auth MFE will handle the redirect
  if (
    error instanceof EnrollmentError &&
    error.errorType === EnrollmentErrorType.UNAUTHENTICATED
  ) {
    return <Loading />;
  }

  return <ErrorElementLayout errorType={enrollmentErrorType} />;
}

export default ErrorElement;
