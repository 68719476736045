import {createContext, useContext} from 'react';
import {Configs} from '../lib/protov2/config';

export type UseConfigsV2Return = {
  configs: Configs;
};

export const ConfigsV2Context = createContext(undefined);

export const useConfigsV2 = (): UseConfigsV2Return => ({
  configs: useContext(ConfigsV2Context),
});
