import {RpcOptions} from '@protobuf-ts/runtime-rpc';
import {
  EnrolleeEvent,
  RecordEnrolleeEventRequest,
} from '@verily-src/verily1-protos/enrollment/bff/api/v2/server';
import {getRpcOptionsMaybeMockingCiam} from '../../utils/clientCaller';
import {client} from './index';

export const makeRecordEnrolleeEventCall = async (
  req: RecordEnrolleeEventRequest,
  opts: RpcOptions
): Promise<void> => {
  await client.recordEnrolleeEvent(req, opts);
};

export const recordEnrolleeEvent = async (
  event: EnrolleeEvent
): Promise<void> => {
  const req: RecordEnrolleeEventRequest = {
    observedTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    observedAt: {
      seconds: BigInt(Math.round(new Date().getTime() / 1000)),
      nanos: 0,
    },
    event,
  };
  await makeRecordEnrolleeEventCall(req, await getRpcOptionsMaybeMockingCiam());
};
