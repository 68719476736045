import {yupResolver} from '@hookform/resolvers/yup';
import {CardMedia, Grid, Stack, Typography, useTheme} from '@mui/material';
import {Button, TextField} from '@verily-src/react-design-system';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {toCommonAssetsPath} from '../../utils';
import AccountCreationBox from '../account-creation-box';

export type AccountCreationLayoutProps = {
  onSubmit: (email: string, setAccountAlreadyExistsMessage: Function) => void;
  submitLoading: boolean;
  onSignIn: () => void;
  signInLoading: boolean;
};

export function AccountCreationLayout(props: AccountCreationLayoutProps) {
  const {t} = useTranslation();
  const logo = toCommonAssetsPath('verily_logo.png');
  const theme = useTheme();
  const schema = yup.object().shape({
    email: yup
      .string()
      .email(t('account-creation-ciam.enter-email-address'))
      .required(t('account-creation-ciam.enter-email-address'))
      .max(321, t('account-creation-ciam.email-length-error')) // 64 + 256 + 1
      .matches(
        /^[^@]{1,64}@/,
        t('account-creation-ciam.email-length-invalid-username')
      )
      .matches(
        /@.{1,255}$/,
        t('account-creation-ciam.email-length-invalid-domain')
      )
      .default(''),
  });

  const {handleSubmit, getValues, formState, control, setError} = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
  });

  const submitEmail = () => {
    props.onSubmit(getValues('email'), setError);
  };

  return (
    <AccountCreationBox>
      <Grid container justifyContent="center">
        <form onSubmit={handleSubmit(submitEmail)} noValidate>
          <Stack
            spacing={theme.spacing(3)}
            alignItems="center"
            justifyContent="center"
          >
            <CardMedia
              component="img"
              src={logo}
              alt="Verily logo"
              sx={{
                maxWidth: '100px',
              }}
            />
            <Typography
              width="75%"
              variant="body1em"
              component="h2"
              textAlign="center"
            >
              {t('account-creation-ciam.start-msg')}
            </Typography>
            <Stack spacing={theme.spacing(3)} width="100%">
              <Controller
                control={control}
                name="email"
                render={({field: {ref: _, ...field}, fieldState: {error}}) => (
                  <TextField
                    required
                    defaultValue=""
                    {...field}
                    error={!!error}
                    placeholder={t(
                      'account-creation-ciam.email-field-placeholder'
                    )}
                    multiline={false}
                    errorText={error?.message}
                  />
                )}
              />
              <Button
                data-cy="submit-button"
                data-testid="submit-button"
                variant="filled"
                label={t('account-creation-ciam.button-label')}
                disabled={!formState.isValid}
                onClick={submitEmail}
                loading={props.submitLoading}
                size="large"
              />
            </Stack>

            <Typography variant="body2" width="100%" component="p">
              {t('account-creation-ciam.existing-account-question')}{' '}
              <Button
                data-cy="sign-in-button"
                data-testid="sign-in-button"
                label={t('account-creation-ciam.sign-in')}
                onClick={props.onSignIn}
                loading={props.signInLoading}
                size="large"
              />
            </Typography>
          </Stack>
        </form>
      </Grid>
    </AccountCreationBox>
  );
}
